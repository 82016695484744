import { useEffect, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Spacer,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  Switch,
  IconButton,
  Wrap,
  AccordionPanel,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

type Group = {
  value: string;
  label: string;
};
export default function UserClientTab(props) {
  const handleSelect = (e) => {
    props.setSelectedGroups([...e]);
  };
  const [radioValue, setRadioValue] = useState('1');

  const handleRadioValue = (e) => {
    setRadioValue(e);
  };

  return (
    <Box width='100%' ml='-20px'>
      {props?.tenantusers}
      <Box
        width='100%'
        borderRadius={'10px'}
        shadow={'base'}
        padding={'20px'}
        background={'white'}
      >
        <Flex width='100%' minW='500px'>
          <FormLabel>SELECT GROUP</FormLabel>
          <Spacer />
          <RadioGroup onChange={handleRadioValue} value={radioValue}>
            <Stack direction='row'>
              <Radio value='1'>All</Radio>
              <Radio value='2'>Active</Radio>
              <Radio value='3'>Inactive</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex width='100%' gap={'20px'}>
          <Box width='100%'>
            <Select
              id='groupSelect'
              placeholder='Select Group'
              size='sm'
              options={props.groups.map(function (g) {
                if (radioValue === '1') {
                  return { value: g.id, label: g.name };
                }
                if (radioValue === '2') {
                  return { value: g.id, label: g.name };
                }
                if (radioValue === '3') {
                  return { value: '', label: '' };
                }
                return { value: g.id, label: g.name };
              })}
              isMulti
              onChange={handleSelect}
              value={props.selectedGroups}
            />
          </Box>
        </Flex>
      </Box>
      <Box padding={'20px'} borderBottomColor={'gray.300'}>
        <Flex gap={'20px'} width={'100%'} alignItems={'center'}>
          <FormLabel margin={'0'} color={'#0899b9'} fontSize='sm'>
            CURRENT GROUPS & CLIENTS
          </FormLabel>
        </Flex>
      </Box>
      <Accordion defaultIndex={[0]} allowMultiple minW='1000px'>
        {props.selectedGroups.map((group, i) => {
          return renderGroup(group, i);
        })}
      </Accordion>
    </Box>
  );

  function renderGroup(group: Group, i: number) {
    return (
      <AccordionItem      
        key={i}
      >
        <Flex>
          <AccordionButton color={'#0899b9'} fontSize='sm'>
            <AccordionIcon color={'black'} mr='5px' />
            <b>{group.label}</b>
            <Spacer />
            <Box color='black' mr='3px'>
              All?
            </Box>
            <Switch
              isChecked={ props.tenants.filter((tenant) => 
                tenant.TenantGroupId === group.value && tenant.checked).length === props.tenants.filter((tenant) => 
                tenant.TenantGroupId === group.value).length}
              size='sm'
              onChange={(e) => {
                props.handleToggle(e, group.value);
              }}
            ></Switch>
          </AccordionButton>

          <IconButton
            size='sm'
            color='red'
            colorScheme='white'
            aria-label='delete'
            icon={<DeleteIcon />}
            onClick={(e) => props.removeGroup(e, group.value)}
          />
        </Flex>

        <AccordionPanel pb={4}>
          <Wrap>
            <CheckboxGroup>
              {props.tenants.map((tenant) => {
                return tenant.TenantGroupId === group.value ? (
                  <Checkbox
                    w='200px'
                    h='40px'
                    pl='10px;'
                    size='sm'
                    key={tenant.TenantId}
                    onChange={(e) =>
                      props.handleTenant(tenant.TenantId, tenant.TenantGroupId)
                    }
                    isChecked={tenant?.checked}
                  >
                    {tenant.name}
                  </Checkbox>
                ) : (
                  ''
                );
              })}
            </CheckboxGroup>
          </Wrap>
        </AccordionPanel>
      </AccordionItem>
    );
  }
}
