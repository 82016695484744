import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  Input,
  Card,
  useColorModeValue,
  Flex,  
  Select,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type AddEditContactFormProps = {
  tenantId: string;
  refetch: () => void;
};

 const initial_data = {
   firstName: '',
   lastName: '',
   phone: '',
   email: '',
   primaryFlag: false,
 };

export default function AddEditContactForm({
  tenantId,
  refetch,
}: AddEditContactFormProps) {
  const [values, setValues] = useState(initial_data);
  const [jobType, changeSelect] = useState({ value: '', label: '' });
  const { register, handleSubmit, reset, formState } = useForm({ values });
  const { run: createContact } = useMojoEffect(
    '/api/v1/contacts/create',
    'post'
  );
  const [isLoading, setLoading] = useState(false);

  const { data: jobTypesData } = useMojoFetch('/api/v1/JobTypes/all', 'get');
  const jobTypeOptions = jobTypesData
    ? jobTypesData.map(({ JobTypeId, name }) => ({
        label: name,
        value: JobTypeId,
      }))
    : [];

  const toast = useToast();

  async function submitForm(data) {
    setLoading(true);
    var createContactDto = {
      ...data,
      TenantId: tenantId,
      activeFlag: true,
      userType: jobType.label,
      JobTypeId: jobType.value,
    };

    const [body, error] = await createContact(createContactDto);
    if (error === null) {
      toast({
        title: 'Contact created',
        status: 'success',
      });
      await refetch();
    } else {
      toast({
        title: 'Failed to create contact',
        status: 'error',
      });
    }
    reset();
    setLoading(false);
  }

  function handleSelect(event): void {
    var foo = [...event.target];
    var selection = foo.find((x) => x.selected);
    changeSelect(selection);
  }

  const bg = useColorModeValue('white.100', 'black.100');
  const color = useColorModeValue('black.700', 'white.100');

  useEffect(() => {
    console.log(formState.errors.email?.message);
    if(formState.errors.email?.message){
      toast({
        title: `${formState.errors.email.message}`,
        status: "error"
      })
    }
  },[formState])

  return (
    <Card p={18} bg={bg} width='100%'>
      <form onSubmit={handleSubmit(submitForm)}>
        <Flex flexDirection='column' width='100%' gap='5px'>
          <FormControl className='form-upper form'>
            <FormLabel>ADD NEW CONTACT</FormLabel>
          </FormControl>
          <Box width='100%' maxWidth='700px'>
            <FormControl variant='floating' isRequired mb='10px'>
              <Input id='firstname' type='text' {...register('firstName')} />
              <FormLabel style={{ paddingRight: '8px' }}>First Name</FormLabel>
            </FormControl>
            <FormControl variant='floating' isRequired mb='10px'>
              <Input id='lastname' type='text' {...register('lastName')} />
              <FormLabel style={{ paddingRight: '8px' }}>Last Name</FormLabel>
            </FormControl>
            <FormControl variant='floating' isRequired mb='10px'>
              <Input
                {...register("email", {
                  required: "E-mail is required", // Required validation
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Regex for email format
                    message: "Invalid email address",
                  },
                })}
                id="email"
                type="email"
              />
              <FormLabel style={{ paddingRight: '8px' }}>E-mail</FormLabel>
            </FormControl>
            <FormControl variant='floating' isRequired mb='10px'>
              <Input id='phone' type='text' {...register('phone')} />
              <FormLabel style={{ paddingRight: '8px' }}>Phone</FormLabel>
            </FormControl>
            {!!jobTypesData && (
              <FormControl variant='floating' isRequired mb='10px'>
                <Select
                  placeholder='Select...'
                  size='xs'
                  height='45px'
                  width='15rem'
                  borderRadius='5'
                  bg={bg}
                  color={color}
                  border='gray.200'
                  onChange={handleSelect}
                >
                  {jobTypeOptions.map((j) => (
                    <option value={j.value}>{j.label}</option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }}>Type</FormLabel>
              </FormControl>
            )}
            <Flex className='bt-add'>
              <FormControl display='flex' alignItems='left' mt='0.5rem'>
                <FormLabel htmlFor='primary-flag'>Primary?</FormLabel>
                <Switch {...register('primaryFlag')} />
              </FormControl>
              <Button
                leftIcon={<AddIcon />}
                variant='mojoPrimary'
                type='submit'
                isLoading={isLoading}
              >
                ADD CONTACT
              </Button>
              <Button ml='70px'
                leftIcon={<CloseIcon />}
                variant='mojoPrimary'
                onClick={(e) => {
                  reset();
                }}
              >
                CLEAR FORM
              </Button>
            </Flex>
          </Box>
        </Flex>
      </form>
    </Card>
  );
}
