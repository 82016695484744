import { useToast, VStack } from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';

import {
  Box,
  Button,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from '@chakra-ui/react';

import UserForm from '../UserForm/UserForm';
import { Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
//import UserPermissionsTab from '../UserPermissionsTab/UserPermissionsTab';
import UserClientTab from '../UserClientTab/UserClientTab';
import { useForm } from 'react-hook-form';

import { useParams } from 'react-router';
import { useMojoFetch } from 'api/useMojoFetch';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useMojoEffect } from 'api/useMojoEffect';
import logo from '../../../logo-teal.png';
/*
  * BE VERY CAREFUL IF YOU TRY TO REFACTOR
  * DUE TO MOJO FETCHES THE FORM IS SENSITIVE TO TIMING BUGS
  * THAT RESULT IN DROP DOWN VALUES NOT APPEARING 
  * (USER ROLE ESPECIALLY)
*/ 
export type UserFormData = {
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  userRole: string;
  userRoleOption: string;
  password: string;
  passwordCopy: string;
};

export type Tenant = {
  TenantId: string;
  name: string;
  TenantGroupId: string;
  checked: boolean;
  Group: any;
};

type UserDto = {
  UserId: string;
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  roles: any;
  password: string;
};

export default function UserCreate() {

    const initialData = useMemo(() => {
      return {
        firstName: '',
        lastName: '',
        phone: '',
        blockFlag: false,
        activeFlag: true,
        email: '',
        userRoleOption: '',
        userRole: '',
        password: '',
        passwordCopy: '',
      };
    }, []);
  const navigate = useNavigate();

  const { userId } = useParams();
  const [myUserId, setUserId] = useState(''); 
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [tenants, setAllTenants] = useState<Tenant[]>([]);
  const [roleOptions, setRoleOptions] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

    const { data: roles, error: roleError } = useMojoFetch(
      '/api/v1/roles/',
      'get'
    );

  const { data: organizations } = useMojoFetch(
    `/api/v1/organizations`,
    'get'
  );
  const { data: agencies } = useMojoFetch(`/api/v1/agencies`, 'get');
  const { data: groups } = useMojoFetch(`/api/v1/groups`, 'get');
  

  const entities = {
      organizations: organizations,
      agencies: agencies,
      groups: groups,
    };

  const { data: user, isLoading: formLoading } = useMojoFetch(
    `/api/v1/users/${userId}`,
    'get'
  );

  const { data: originalTenants, error: tenantError } = useMojoFetch(
    '/api/v1/Clients/',
    'get'
  );

  const [isClient, setIsClient] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { ...initialData } });

  const resetForm = useCallback(() => {
    reset(initialData);
    setRoleOptions([]);
    setSelectedGroups([]);
    tenants.map((st) => (st.checked = false));
    setAllTenants([...tenants]);
    setUserId('');
  },[initialData, reset, tenants]);


  useEffect(() => {

    const doRoles = async () => {
      const myTenants = originalTenants.map((d) => ({
        ...d,
        checked: false,
      }));
      //Get the full user
      if (
        userId !== undefined &&
        userId !== 'new'
      ) {
        setUserId(userId);

        if (
          user.roles.Organizations !== undefined &&
          user.roles.Organizations.length > 0
        ) {
          user.userRole = user.roles.Organizations[0].role_id;
          user.userRoleOption = user.roles.Organizations[0].entity_id;
          setRoleOptions(entities.organizations);
        } else if (
          user.roles.Agencies !== undefined &&
          user.roles.Agencies.length > 0
        ) {
          user.userRole = user.roles.Agencies[0].role_id;
          user.userRoleOption = user.roles.Agencies[0].entity_id;
          setRoleOptions(entities.agencies);
        } else if (
          user.roles.Groups !== undefined &&
          user.roles.Groups.length > 0
        ) {
          user.userRole = user.roles.Groups[0].role_id;
          user.userRoleOption = user.roles.Groups[0].entity_id;
          setRoleOptions(entities.groups);
        } else if (
          user.roles.Clients !== undefined &&
          user.roles.Clients.length > 0
        ) {
          setIsClient(true);
          user.userRole = user.roles.Clients[0].role_id;
          user.roles.Clients.forEach((t) => {
            const tenant = myTenants.find((x) => x.TenantId === t.entity_id);
            if (tenant) {
              tenant.checked = true;
            }
          });

          const groupIds = new Set<string>(
            myTenants.filter((t) => t.checked).map((t) => t.Group.Id)
          );
          setSelectedGroups(
            groups
              .filter((g) => Array.from(groupIds).includes(g.id))
              .map((g) => {
                return { value: g.id, label: g.name };
              })
          );
        }        
        reset(user);
      } else {
        resetForm();
      }
      setAllTenants([...myTenants]);
    };
    if (user === undefined || user.roles === undefined) {
      return;
    }
    doRoles();
  }, [user, userId]);

  const { run: createUser } = useMojoEffect(`/api/v1/users/`, 'post');
  const { run: updateUser } = useMojoEffect(`/api/v1/users/${myUserId}`, 'put');

  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    setLoading(true);
    const { userRole, userRoleOption, ...cleanUser } = data;   
    var userDto: UserDto;
   
    let role = roles.find((x) => x.Id === userRole);
    let roleOption = roleOptions.find((x) => x.id === userRoleOption);

    const myRoles = {
      Organizations: [] as any,
      Agencies: [] as any,
      Groups: [] as any,
      Clients: [] as any,
    };

    if (role.name.includes('Organization')) {
      myRoles.Organizations.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Agency')) {
      myRoles.Agencies.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Group')) {
      myRoles.Groups.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Client')) {
      tenants
        .filter((t) => t.checked === true)
        .forEach((t) => {
          myRoles.Clients.push({
            role_id: role.Id,
            name: role.name,
            entity_id: t.TenantId,
            entity: t.name,
          });
        });
    }

    userDto = {
      ...cleanUser,
      UserId: '',
      roles: myRoles,
    };

    if (userDto.password !== data.passwordCopy) {
      setLoading(false);
      toast({
        title: 'Passwords do not match',
        status: 'error',
      });
      return;
    }

    if (myUserId !== '') {
      userDto.UserId = myUserId;
      const [body, error] = await updateUser(userDto);
      if (error === null) {
        toast({
          title: 'User updated',
          status: 'success',
        });
      } else {
        toast({
          title: 'User update failed',
          status: 'error',
        });
      }
    } else {
      const [body, error] = await createUser(userDto);
      if (error === null) {
        toast({
          title: 'User created',
          status: 'success',
        });
        setUserId(body.UserId);
      } else {
        toast({
          title: 'User creation failed',
          status: 'error',
        });
      }
    }
    setLoading(false);
    return data;
  };

  const handleTenant = (tenantId, groupId) => {
    var tenant = tenants.find((t) => t.TenantId === tenantId);

    if (tenant != null) {
      tenant.checked = !tenant?.checked;
    }
    setAllTenants([...tenants]);
  };

  const handleAllToggle = (e, groupId) => {
    var group = groups.find((g) => g.id === groupId);

    var someTenants = tenants.filter((t) => t.TenantGroupId === groupId);

    if (e.target.checked) {
      if (group != null) {
        group.checked = true;
        someTenants.map((st) => (st.checked = true));
      }
    } else {
      if (group != null) {
        group.checked = false;
        someTenants.map((st) => (st.checked = false));
      }
    }

    setAllTenants([...tenants]); //useState() hook so that userClient part of form re-renders
  };

  if (formLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  return (
    <VStack align='left'>
      <Flex ml='2em' top='7em' width={'500px'} position='fixed'>
        <Button
          leftIcon={
            <ChevronLeftIcon height={6} width={'auto'} color={'cyan'} />
          }
          variant={'mojoDefault'}
          onClick={() => navigate('/users')}
        >
          <Text>USERS</Text>
        </Button>

        <Center w='150px'>
          <Text>
            <b>
              {myUserId === '' || myUserId === undefined || myUserId === 'new'
                ? 'ADD NEW USER'
                : 'UPDATE USER'}
            </b>
          </Text>
        </Center>
      </Flex>
      <Flex>        
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            isLoading={isLoading}
            register={register}
            userId={myUserId}
            roles={roles}
            roleOptions={roleOptions}
            //defaultRoleValue={myUser.userRole}
            //defaultRoleOption={myUser.userRoleOption}
            setRoleOptions={setRoleOptions}
            resetForm={resetForm}
            entities={entities}
            setIsClient={setIsClient}
          />
        </form>
        <Box width='5%' />
        <Tabs width='50%' display={isClient ? 'block' : 'none'}>
          <TabList>
            <Tab>CLIENTS</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UserClientTab
                groups={groups}
                tenants={tenants}
                handleTenant={handleTenant}
                handleToggle={handleAllToggle}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </VStack>
  );
}
