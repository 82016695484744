import { useState, useEffect } from "react";
import {
    Box,
    useColorModeValue,
    Heading,
    Card,
    CardHeader,
    CardBody,
    Stack,
    Text,
    Button,
    Flex,
    Divider,
    useColorMode,
    Spinner
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import "./kpi-widgets.scss";

function WebsiteTrafficWidget({
    isActive,
    onClick,
    data,
    isLoading
}) {
    const [selectedKpi, setSelectedKpi] = useState('');

    // light/dark mode
    const { colorMode } = useColorMode();
    const bg = useColorModeValue('white.100', '#282828');
    const cardBackground = useColorModeValue('white', '#282828');
    const fontColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', '#3f3f3f');
    const titleColor = useColorModeValue('black.500', 'white');
    const axisColor = useColorModeValue('#43425D', 'white')

    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'NEW USERS',
                data: data.dataSeries.map((x) =>
                    x.newUsers === undefined ? 0 : x.newUsers
                ),
                type: 'column',
                color: '#73CEE2',
            },
            {
                name: 'TOTAL USERS',
                data: data.dataSeries.map((x) =>
                    x.totalUsers === undefined ? 0 : x.totalUsers
                ),
                type: 'column',
                color: '#2F8DA2',
            },
            {
                name: 'LEAD',
                data: data.dataSeries.map((x) =>
                    x.leadCount === undefined ? 0 : x.leadCount
                ),
                type: 'column',
                color: '#2F8DA2',
            },
        ],
    });
    const [chartOption, setChartOption] = useState<ApexOptions>({
        title: {
            style: {
                color: titleColor,
            },
        },
        chart: {
            id: '_websiteTrafficWidget',
            toolbar: {
                show: false,
            },
            width: '100%',
            background: bg,
        },
        plotOptions: {
            bar: {
                columnWidth: '70%',
            },
        },
        stroke: {
            colors: ['transparent'],
            width: 3,
        },
        xaxis: {
            categories: data.categories,
            labels: {
                show: true,
                style: {
                    colors: axisColor, //purple.500
                    fontSize: '0.5625rem', //9px
                    fontFamily: `'Poppins', sans-serif`, // poppins
                },
            },
        },
        yaxis: {
            tickAmount: 4,
            min: 0,
            max:
                Math.max(
                    ...data.dataSeries
                        .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
                        .concat(
                            data.dataSeries
                                .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                                .concat(
                                    data.dataSeries.map((x) =>
                                        x.leadCount === undefined ? 0 : x.leadCount
                                    )
                                )
                        )
                ) === 0
                    ? 10
                    : Math.ceil( 
                        Math.max(
                          ...data.dataSeries
                            .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
                            .concat(
                              data.dataSeries
                                .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                                .concat(
                                  data.dataSeries.map((x) =>
                                    x.leadCount === undefined ? 0 : x.leadCount
                                    )
                                  )
                                )
                            ) / 1000
                        ) * 1000,
            labels: {
                style: {
                    colors: axisColor, //purple.500
                    fontSize: '0.5625rem', //9px
                    fontFamily: `'Poppins', sans-serif`, // poppins
                },
                formatter: function(value) {
                    return `${value.toLocaleString()}`;
                }
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetX: 0,
            offsetY: 20,
            fontSize: '8px',
            fontFamily: 'gothamMedium',
            fontWeight: 500,
            labels: {
                colors: '#393939',
            },
            itemMargin: {
                horizontal: 10,
            },
            markers: {
                strokeWidth: 1,
            },
        },
    });

    useEffect(() => {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          background: bg, 
        },
        yaxis: Array.isArray(prevOptions.yaxis)
          ? prevOptions.yaxis.map(y => ({
              ...y,
              labels: {
                ...y.labels,
                style: {
                  ...y.labels?.style,
                  colors: axisColor,
                },
              },
            }))
          : {
              ...prevOptions.yaxis,
              labels: {
                ...prevOptions.yaxis?.labels,
                style: {
                  ...prevOptions.yaxis?.labels?.style,
                  colors: axisColor,
                },
              },
          },
        xaxis: {
          ...prevOptions.xaxis,
          labels: {
            ...prevOptions.xaxis?.labels,
            style: {
              ...prevOptions.xaxis?.labels?.style,
              colors: axisColor,
            },
          },
        },
      }));
    }, [colorMode, bg, axisColor]);

    return (
      <Box
        w='100%'
        h='100%'
        maxH='200px'
        className={`widget ${isActive ? 'active' : ''}`}
        onClick={onClick}
        borderColor={'mojo.200'}
      >
        <Flex
          className='kpi-widget--container'
          bg={bg}
          borderColor={borderColor}
        >
          <Box className='kpi-widget-chart--wrap'>
            <Heading
              size={'lg'}
              className='kpi-widget--heading'
              color={titleColor}
            >
              WEBSITE TRAFFIC
            </Heading>
            <Flex className='kpi-widget--spinner'>
              {isLoading ? (
                <Spinner size='sm' />
              ) : (
                <Chart
                  className='kpi-widget--chart'
                  options={chartOption}
                  series={chartData.series}
                  type='line'
                  width='100%'
                  height='100%'
                />
              )}
            </Flex>
          </Box>

          <Flex className='kpi-widget-card--wrap'>
            <Card
              className='kpi-widget--card'
              bg={cardBackground}
              borderColor={borderColor}
            >
              <CardHeader className='kpi-card--header'>
                <Heading className='kpi-card--heading' color={fontColor}>
                  Avg Leads Per User
                </Heading>
              </CardHeader>
              <Divider />
              <CardBody className='kpi-card--body'>
                <Stack className='kpi-card--body-stack'>
                  <Box className='kpi-card--pos-avg'>
                    <Text>
                      {' '}
                      {data.avg_leads_per_visitor !== null &&
                      data.avg_leads_per_visitor !== undefined
                        ? data.avg_leads_per_visitor
                        : 'n/a'}
                    </Text>
                  </Box>

                  <Box className='kpi-card--details'>
                    <Box className='kpi-card--detail-item'>
                      <Text textAlign='left' color={fontColor}>
                        Nation
                      </Text>
                      <Text textAlign='right' color={fontColor}>
                        $--
                      </Text>
                    </Box>
                    <Box className='kpi-card--detail-item'>
                      <Text textAlign='left' color={fontColor}>
                        Brand
                      </Text>
                      <Text textAlign='right' color={fontColor}>
                        $--
                      </Text>
                    </Box>
                    <Box className='kpi-card--detail-item'>
                      <Text textAlign='left' color={fontColor}>
                        Similar Stores
                      </Text>
                      <Text textAlign='right' color={fontColor}>
                        $--
                      </Text>
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    <Button
                      color={'white.100'}
                      fontSize={'xs8'}
                      bgColor={'mojo.200'}
                      p={'5px 2px'}
                      h={'auto'}
                    >
                      VIEW FULL REPORT
                    </Button>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      </Box>
    );
}

export default WebsiteTrafficWidget;