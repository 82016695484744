import { Params, useNavigate, useParams } from 'react-router-dom';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { useState } from 'react';
import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { VendorForm } from '../VendorForm';
import { EditIcon } from '@chakra-ui/icons';
import logo from '../../../logo-teal.png';

function VendorEditForm({
  partnerId,
  revieweesData,
  vendorTypesData,
  vendorRecommendsData,
}) {
  const { run: runUpdate } = useMojoEffect(
    `/api/v1/Vendors/${partnerId}`,
    'PUT'
  );

  const {
    data: vendorData,
    error: vendorError,
    isLoading,
  } = useMojoFetch(`/api/v1/Vendors/${partnerId}`, 'get');

  const toast = useToast();

  const [isSubmitting, setSubmit] = useState(false);

  return (
    <VendorForm
      isSubmitting={isSubmitting}
      isCreatingNew={false}
      isLoading={false}
      revieweesData={revieweesData}
      vendorTypesData={vendorTypesData}
      vendorRecommendsData={vendorRecommendsData}
      submit={async (args) => {
        setSubmit(true);
        if (args.RevieweeId === '') {
          args.RevieweeId = null;
        }
        if (args.VendorRecommendId === '') {
          args.VendorRecommendId = null;
        }
        const updateVendorData = {
          name: args.name,
          VendorTypeId: args.VendorTypeId,
          presenter: args.presenter,
          reviewDate: args.reviewDate,
          companyLaunch: args.companyLaunch,
          website: args.website,
          acctsPerRep: args.acctsPerRep,
          //terms: args.terms,
          //creative: args.creative,
          //customerService: args.customerService,
          //planning: args.planning,
          //reporting: args.reporting,
          //execution: args.execution,
          notes: args.notes,
          numberOfClients: args.numberOfClients,
          numberOfEmployees: args.numberOfEmployees,
          RevieweeId: args.RevieweeId,
          VendorRecommendId: args.VendorRecommendId,
        };
        //setVendorId(VendorId);
        const [updatedData, error] = await runUpdate(updateVendorData);
        if (error === null) {
          toast({
            title: 'Partner updated',
            status: 'success',
          });
          setSubmit(false);
          return updatedData;
        } else {
          toast({
            title: 'Partner update failed',
            status: 'error',
          });
          setSubmit(false);
          return args;
        }
      }}
      defaultValues={vendorData}
      btnTitle='UPDATE PARTNER'
      btnIcon={<EditIcon />}
    />
  );
}

function VendorAddForm({
  onlyRequired,
  revieweesData,
  vendorTypesData,
  vendorRecommendsData,
}: {
  onlyRequired?: boolean;
  revieweesData: [];
  vendorTypesData: [];
  vendorRecommendsData: [];
}) {
  const navigate = useNavigate();
  const [isSubmitting, setSubmit] = useState(false);
  const { run: runCreate } = useMojoEffect('/api/v1/Vendors/create', 'post');

  const toast = useToast();
  return (
    <VendorForm
      isSubmitting={isSubmitting}
      vendorTypesData={vendorTypesData}
      vendorRecommendsData={vendorRecommendsData}
      defaultValues={{
        name: '',
        VendorTypeId: null,
        RevieweeId: null,
        VendorRecommendId: null,
        presenter: '',
        reviewDate: undefined,
        companyLaunch: undefined,
        website: '',
        notes: '',
        acctsPerRep: '',
        numberOfEmployees: '',
        numberOfClients: '',
      }}
      revieweesData={revieweesData}
      isCreatingNew={true}
      isLoading={false}
      submit={async (args) => {
        setSubmit(true);
        if (args.RevieweeId === '') {
          args.RevieweeId = null;
        }
        if (args.VendorRecommendId === '') {
          args.VendorRecommendId = null;
        }
        const [createdData, error] = await runCreate({
          ...args,
          numberOfClients: Number(args.numberOfClients),
          numberOfEmployees: Number(args.numberOfEmployees),
          acctsPerRep: Number(args.acctsPerRep),
        });
        if (error === null) {
          toast({
            title: 'Partner created',
            status: 'success',
          });
          setSubmit(false);
          navigate(`/partners/${createdData.VendorId}`);
        } else {
          toast({
            title: 'Partner create failed',
            status: 'error',
          });
        }
        setSubmit(false);
      }}
      btnTitle='ADD PARTNER'
      btnIcon={<EditIcon />}
      //formTitle={`CREATE NEW PARTNER`}
    />
  );
}

export function AddEditVendorsForm({
  partnerId,
  onlyRequired,
}: {
  partnerId: Readonly<Params<string>>;
  onlyRequired?: boolean;
}) {
  const { data: revieweesData, isLoading: isRevieweeDataLoading } =
    useMojoFetch(`/api/v1/Users`, 'get');

  const { data: vendorTypesData, isLoading: isVendorTypesDataLoading } =
    useMojoFetch('/api/v1/VendorsTypes/all', 'get');

  const {
    data: vendorRecommendsData,
    isLoading: isVendorRecommendsDataLoading,
  } = useMojoFetch('/api/v1/VendorRecommends/all', 'get');

  const p = { ...partnerId };
  if (
    isRevieweeDataLoading ||
    isVendorTypesDataLoading ||
    isVendorRecommendsDataLoading
  ) {
    return (
      <>
        <Container maxW='500px'>Loading....</Container>
        <Spinner />
      </>
    );
  }
  if (
    p.partnerId === 'undefined' ||
    p.partnerId === undefined ||
    p.partnerId === 'new'
  ) {
    return (
      <VendorAddForm
        onlyRequired={onlyRequired}
        revieweesData={revieweesData}
        vendorTypesData={vendorTypesData}
        vendorRecommendsData={vendorRecommendsData}
      />
    );
  }
  return (
    <VendorEditForm
      partnerId={p.partnerId}
      revieweesData={revieweesData}
      vendorTypesData={vendorTypesData}
      vendorRecommendsData={vendorRecommendsData}
    />
  );
}
