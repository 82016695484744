import { AddIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  useToast,
  Input,
  Flex,
  FormLabel,
  Spacer,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

export default function AddEditAgencyForm(props) {  
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState(null);
  const [key, setKey] = useState(1);

  const { register, handleSubmit, reset } = useForm();
  const { data: organizations } = useMojoFetch(`/api/v1/organizations`, 'get');
  const { run: create } = useMojoEffect(
    `/api/v1/agencies`,
    'post'
  );
  const { run: update } = useMojoEffect(
    `/api/v1/agencies/${props.agency.id}`,
    'put'
  );
  const [selOrganizations, setSelectedOrganizations] = useState([
    { value: '', label: '' },
  ]);

  const toast = useToast();
  const labelProps = { transform: 'scale(0.85) translateY(-24px)' };
  

  useEffect(() => {    
    if (props.agency.id !== '') {                
      setSelectedOrganizations(props.agency.providers);       
      setValues(props.defaultValues);
    }
  }, [props]);

  function handleChange(e) {
    let orgs: any[] = [];
    e.map((e) => orgs.push({ id: e.value, name: e.label }));
    setSelectedOrganizations(orgs);
    setValues(e);
  }

  const myReset = () => {    
    reset({ name: '' });
    setSelectedOrganizations([]);   
    setValues(null);
    setKey(key+1);
    props.reset();    
  };

  async function submitForm(data) {
    setLoading(true);
    var agencyDto = {
      ...data,
      providers: selOrganizations,
    };

    if (props.agency.id !== '') {
       const [body, updateError ] = await update(agencyDto);
       
        if (updateError === null) {
        
          toast({
            title: 'Agency updated',
            status: 'success',
          });
          await props.refetch();                   
        } else {
          toast({
            title: 'Failed to update agency',
            status: 'error',
          });
        }      
    } else {
      const [body, creationError] = await create(agencyDto);
        if (creationError === null) {        
          toast({
            title: 'Agency created',
            status: 'success',
          });
          await props.refetch();
        } else {
          toast({
            title: 'Failed to create agency',
            status: 'error',
          });
        }
    }
    setLoading(false);
    }
  

  return (
    <form onSubmit={handleSubmit(async (data) => await submitForm(data))}>
      <Box className='form-upper-wrapper' mt='50px'>
        <Box className='form-upper form'>
          <Box className='form-upper-title'>ADD / EDIT AGENCY</Box>
        </Box>
        <Flex w='90%'>
          <FormControl variant='floating' isRequired size='md' w='350pt'>
            <Input
              width='300pt'
              mb='30px'
              {...register('name')}
              defaultValue={props.agency.name}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Name</FormLabel>
          </FormControl>
          <FormControl variant='floating' isRequired width='470pt'>
            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
              Organization
            </FormLabel>
            <Select
              aria-label= 'organization'
              key={key}
              size='sm'
              value={values}
              options={organizations.map((o) => {
                return { value: o.id, label: o.name };
              })}
              isMulti
              className='basic-multi-select'
              classNamePrefix='chakra-react-select'
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </FormControl>
          <Button variant='mojoPrimary' type='submit' aria-label="submit" >
            {props.agency.id === '' ? <AddIcon /> : <EditIcon />}
          </Button>
          <Button variant='mojoPrimary' onClick={(e) => myReset()}>
            <CloseIcon />
          </Button>
        </Flex>
      </Box>
    </form>
  );
}
