import { Box, Center, Flex, Image } from '@chakra-ui/react';
import UserListDataTable from '../UserListDataTable/UserListDataTable';
import logo from '../../../logo-teal.png';
import { useEffect, useState } from 'react';
import { useMojoEffect } from 'api/useMojoEffect';
import { connect } from 'react-redux';

 function UserList(props) {
   const [fetchError, setError] = useState(null as any);
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const { runWithId: getUsers } = useMojoEffect(`/api/v1/users?`, 'GET');
  
    useEffect(() => {
      const fetch = async () => {
        setLoading(true);
        const [myUsers, error] = await getUsers(`groupId=${props.groupId}`);
        if (error === null) {
          setUsers(myUsers);
        } else {
          setError(error);
        }
        setLoading(false);
      };
      fetch();
    }, [props.groupId]);
  
    const reset = async () => {
    setLoading(true);
    const [myUsers, error] = await getUsers(`groupId=${props.groupId}`);
    if (error === null) {
      setUsers(myUsers);
    } else {
      setError(error);
    }
    setLoading(false);;
  }

  
  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  if (fetchError !== null) {
    return <Center>{fetchError.message}</Center>;
  }

  return (   
    <div className='client-list'>
      <UserListDataTable data={users}/>
    </div>    
  );
}

const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(UserList);
