import { useState } from 'react';
import '../../../components/DataTable/MojoTableStyles.scss';
import './GoalsTable.scss';
import { Form, Input, InputNumber, Table, Modal, Tooltip } from 'antd';
import { useMojoEffect } from 'api/useMojoEffect';
import { Box, Flex, Button, HStack, Spinner, useToast } from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import { DeleteIcon } from '@chakra-ui/icons';
import ColumnFilters from 'components/DataTable/Filters/ColumnFilter/ColumnFilters';
import DepartmentSelect from '../Selects/DepartmentSelect';
import GoalTypeSelect from '../Selects/GoalTypeSelect';

const header = [
  'location',
  'type',
  'department',
  'notes',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'avg',
  'total',
  'actions',
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = <Input />;

  if (editing) {
    inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    if (dataIndex === 'department') {
      inputNode = <DepartmentSelect />;
    } else if (dataIndex === 'type') {
      inputNode = <GoalTypeSelect />;
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: ['type', 'department'].includes(dataIndex),
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function GoalsTable({
  data,
  year,
  location_name,
  goalTypes,
  departments,
  columnVisibility,
  setColumnVisibility,
}) {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(data);
  const [editingKey, setEditingKey] = useState('');
  const [loadingRow, setLoadingRow] = useState({});
  const [usersColumnVisibility, setUsersColumnVisibility] =
    useState(columnVisibility);
  const [selectedLocation] = useState(() => {
    return localStorage.getItem('location_name') || location_name;
  });

  const columns = [
    {
      title: 'Location',
      dataIndex: header[0],
      key: 'location',
      fixed: 'left',
      width: 150,
      // defaultSortOrder: 'ascend',
      editable: false,
      sorter: (a, b) => {
        if (a.location === null || b.location === null) return 0;
        return (a.location ?? '').localeCompare(b.location ?? '');
      },
    },
    {
      title: 'Type',
      dataIndex: header[1],
      key: 'type',
      fixed: 'left',
      width: 100,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.type === null || b.type === null) return 0;
        return (a.type ?? '').localeCompare(b.type ?? '');
      },
    },
    {
      title: 'Dept',
      dataIndex: header[2],
      key: 'department',
      fixed: 'left',
      width: 120,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.department === null || b.department === null) return 0;
        return (a.department ?? '').localeCompare(b.department ?? '');
      },
    },
    {
      title: 'Notes',
      dataIndex: header[3],
      key: 'notes',
      width: 300,
      editable: true,
      render: (text) => (
        <Tooltip title={text}>
          <span className='notes-column'>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Jan',
      dataIndex: header[4],
      key: 'january',
      width: 110,
      editable: true,
    },
    {
      title: 'Feb',
      dataIndex: header[5],
      key: 'february',
      width: 110,
      editable: true,
    },
    {
      title: 'Mar',
      dataIndex: header[6],
      key: 'march',
      width: 110,
      editable: true,
    },
    {
      title: 'Apr',
      dataIndex: header[7],
      key: 'april',
      width: 110,
      editable: true,
    },
    {
      title: 'May',
      dataIndex: header[8],
      key: 'may',
      width: 110,
      editable: true,
    },
    {
      title: 'June',
      dataIndex: header[9],
      key: 'june',
      width: 110,
      editable: true,
    },
    {
      title: 'July',
      dataIndex: header[10],
      key: 'july',
      width: 110,
      editable: true,
    },
    {
      title: 'Aug',
      dataIndex: header[11],
      key: 'august',
      width: 110,
      editable: true,
    },
    {
      title: 'Sept',
      dataIndex: header[12],
      key: 'september',
      width: 110,
      editable: true,
    },
    {
      title: 'Oct',
      dataIndex: header[13],
      key: 'october',
      width: 110,
      editable: true,
    },
    {
      title: 'Nov',
      dataIndex: header[14],
      key: 'november',
      width: 110,
      editable: true,
    },
    {
      title: 'Dec',
      dataIndex: header[15],
      key: 'december',
      width: 110,
      editable: true,
    },
    {
      title: 'Average',
      dataIndex: header[16],
      key: 'avg',
      width: 110,
      editable: false,
    },
    {
      title: 'Total',
      dataIndex: header[17],
      key: 'total',
      width: 110,
      editable: false,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <HStack className='actions-editing-btn-group'>
            {isRowLoading(record.goalId) ? (
              <Spinner size='sm' color={'mojo.200'} />
            ) : (
              <>
                <Button
                  className='action-btn--save'
                  onClick={() => save(record.goalId)}
                  disabled={isRowLoading(record.goalId)}
                >
                  Save
                </Button>
                <Button
                  className='action-btn--cancel'
                  onClick={() => handleCancel(record)}
                >
                  Cancel
                </Button>
              </>
            )}
          </HStack>
        ) : (
          <HStack className='actions-btn-group'>
            {isRowLoading(record.goalId) ? (
              <Spinner size='sm' color={'mojo.200'} />
            ) : (
              <Button
                className='action-btn--edit'
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
              >
                <BsPencilSquare />
              </Button>
            )}
            <Button
              className='action-btn--delete'
              onClick={() => deleteGoalItem(record.goalId)}
            >
              <DeleteIcon />
            </Button>
          </HStack>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
          'avg',
          'total',
        ].includes(col.dataIndex)
          ? 'number'
          : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const visibleColumnsOptions = columns.map(({ key, title }) => ({
    label: typeof title === 'string' ? title : (key as string),
    value: key as string,
  }));

  const { run: createGoal } = useMojoEffect(`/api/v1/Goals`, 'POST');
  const { runWithId: updateGoal } = useMojoEffect(`/api/v1/Goals/`, 'PUT');
  const { runWithId: deleteGoal } = useMojoEffect('/api/v1/Goals/', 'DELETE');

  const isEditing = (record) => record.goalId === editingKey;
  const edit = (record) => {
    setUsersColumnVisibility(columnVisibility);
    setColumnVisibility(header);
    // console.log("Data Source:", dataSource);
    form.setFieldsValue({
      location: location_name,
      type: '',
      department: '',
      notes: '',
      january: '',
      february: '',
      march: '',
      april: '',
      may: '',
      june: '',
      july: '',
      august: '',
      september: '',
      october: '',
      november: '',
      december: '',
      avg: '',
      total: '',
      year: year,

      ...record,
    });
    setEditingKey(record.goalId);
  };

  const cancel = () => {
    setEditingKey('');
    setColumnVisibility(usersColumnVisibility);
  };

  const toast = useToast();

  const save = async (goalId: string) => {
    setLoadingRow((prev) => ({ ...prev, [goalId]: true }));
    let row;
    try {
      row = await form.validateFields();
    } catch {
      setLoadingRow((prev) => ({ ...prev, [goalId]: false }));
      return;
    }
    try {
      let t = goalTypes.find((tp) => tp.name === row.type);
      if (t === undefined) {
        t = goalTypes.find((tp) => tp.id === row.type);
        row.type = t.name;
      }

      let dept = departments.find((d) => d.name === row.department);
      if (dept === undefined) {
        dept = departments.find((d) => d.id === row.department);
        row.department = dept.name;
      }

      row.location = location_name;
      row.january = row.january ? row.january.toString() : '';
      row.february = row.february ? row.february.toString() : '';
      row.march = row.march ? row.march.toString() : '';
      row.april = row.april ? row.april.toString() : '';
      row.may = row.may ? row.may.toString() : '';
      row.june = row.june ? row.june.toString() : '';
      row.july = row.july ? row.july.toString() : '';
      row.august = row.august ? row.august.toString() : '';
      row.september = row.september ? row.september.toString() : '';
      row.october = row.october ? row.october.toString() : '';
      row.november = row.november ? row.november.toString() : '';
      row.december = row.december ? row.december.toString() : '';
      row.avg = row.avg ? row.avg.toString() : '';
      row.total = row.total ? row.total.toString() : '';
      row.year = year.toString();

      const newData = [...dataSource];
      const isNewRow = goalId === undefined || goalId === '';

      if (isNewRow) {
        const { createdItem, error } = await handleCreateGoal(row);

        if (!error) {
          newData[0] = { ...newData[0], ...createdItem };
          setDataSource([...newData]);
          setEditingKey('');
          setLoadingRow((prev) => ({ ...prev, [goalId]: false }));
          Modal.success({
            content: 'Goal created successfully!',
          });
        } else {
          throw new Error('Failed to create new goal item');
        }
        setColumnVisibility(usersColumnVisibility);
        return;
      }

      // existing budget
      const index = newData.findIndex((item) => goalId === item.goalId);
      const { updatedItem, error } = await handleUpdateGoal(goalId, row);

      if (!error) {
        newData.splice(index, 1, { ...newData[index], ...updatedItem });
        setDataSource([...newData]);
        setEditingKey('');
        setLoadingRow((prev) => ({ ...prev, [goalId]: false }));
        Modal.success({
          content: 'Goal updated successfully!',
        });
      } else {
        throw new Error('Failed to update goal');
      }
    } catch (errInfo) {
      setLoadingRow((prev) => ({ ...prev, [goalId]: false }));
      console.log('Validate Failed:', errInfo);
      Modal.error({
        content: 'Failed to save goal',
      });
    }
    setColumnVisibility(usersColumnVisibility);
  };

  const handleCreateGoal = async (goalItem: any) => {
    const [createdItem, error] = await createGoal(goalItem);
    return { createdItem, error };
  };

  const handleUpdateGoal = async (goalId: string, row: any) => {
    const [updatedItem, error] = await updateGoal(goalId, row);
    return { updatedItem, error };
  };

  const deleteGoalItem = async (goalId: string) => {
    const [, error] = await deleteGoal(goalId);
    if (error === null) {
      setLoadingRow((prev) => ({ ...prev, [goalId]: true }));
      const index = dataSource.findIndex((item) => item.goalId === goalId);
      dataSource.splice(index, 1);
      setDataSource([...dataSource]);
      Modal.success({
        content: 'Goal deleted',
      });
    } else {
      setLoadingRow((prev) => ({ ...prev, [goalId]: false }));
      Modal.error({
        content: 'Error deleting goal, please try again. ',
      });
    }
  };

  const handleCancel = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel?',
      onOk: () => {
        if (!record.goalId) {
          const newData = dataSource.filter((item) => item !== record);
          setDataSource(newData);
        }
        cancel();
      },
    });
  };

  const handleAddRow = () => {
    setUsersColumnVisibility(columnVisibility);
    setColumnVisibility(header);
    const newRow = {
      location: location_name,
      type: '',
      department: '',
      notes: '',
      january: '',
      february: '',
      march: '',
      april: '',
      may: '',
      june: '',
      july: '',
      august: '',
      september: '',
      october: '',
      november: '',
      december: '',
      avg: '',
      total: '',
      year: year,
    };
    setDataSource([newRow, ...dataSource]);
    setEditingKey('');
    edit(newRow);
  };

  const isRowLoading = (goalId) => loadingRow[goalId];

  return (
    <Box className='goals-table'>
      <Flex className='table-header'>
        {/* Will be used at a later date 
                <Flex className="data-toggle--wrapper">
                    <Switch 
                        id="actualGoalsDataToggle"
                        checkedChildren="Actual"
                        unCheckedChildren="Goals"
                        onChange={() => setUseActualData(!useActualData)} 
                    />
                </Flex> */}
        <Button
          isDisabled={selectedLocation === ''}
          className='add-row--btn primary-btn'
          onClick={handleAddRow}
        >
          Add Item
        </Button>
        <ColumnFilters
          options={visibleColumnsOptions}
          value={columnVisibility}
          onChange={setColumnVisibility}
        />
      </Flex>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns
            .filter((column) => columnVisibility.includes(column.key as string))
            .map((column) => ({
              ...column,
              fixed:
                column.fixed === 'left'
                  ? 'left'
                  : column.fixed === 'right'
                  ? 'right'
                  : undefined,
            }))}
          scroll={{ x: 1000, y: 500 }}
          pagination={false}
        />
      </Form>
    </Box>
  );
}

export default GoalsTable;
