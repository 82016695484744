import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Flex, Tag, useToast } from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { DataTable } from 'components/DataTable/DataTable';
import InputTextFilter from 'components/DataTable/Filters/InputTextFilter';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';

const columns: (Column<
  components['schemas']['UserDto'] & {
    company: string;
    client: string;
    groupAccess: boolean;
    last_login: string;
  }
> & {
  disabled?: boolean;
  label?: (contact: components['schemas']['UserDto']) => string;
})[] = [
  {
    Header: 'FIRST NAME',
    accessor: 'firstName',
  },
  {
    Header: 'LAST NAME',
    accessor: 'lastName',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
    Filter: (props) => (
      <InputTextFilter
        column={{ ...props.column, filterPlaceholder: 'Search' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'PHONE',
    accessor: 'phone',
  },
  {
    Header: 'ROLE',
    accessor: 'userRole',
  },
  {
    Header: 'Type',
    accessor: 'userTypes',
  },
  {
    Header: 'COMPANY',
    accessor: 'company',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Company/Tenant',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  /*
	{
		Header: "CLIENT",
		accessor: "client",
	},
	
	{
		Header: "GROUP ACCESS",
		accessor: "groupAccess",
		Cell: ({value}) => (
			<Flex justifyContent={"center"}>
				{value ? (
					<CheckIcon color={"green.400"} />
				) : (
					<CloseIcon color={"red.400"} />
				)}
			</Flex>
		),
		Filter: (props) => (
			<SelectFilter column={{...props.column, filterPlaceholder: "Group", className:"fix-select-2x-larger"}} />
		),
		filter: "rankedMatchSorter",
	},
	*/
  {
    Header: 'STATUS',
    accessor: 'activeFlag',
    Cell: ({ row, data }) => {
      if (data[row.index].blockFlag) {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='red'
          >
            Blocked
          </Tag>
        );
      } else if (data[row.index].activeFlag) {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='green'
          >
            Active
          </Tag>
        );
      } else {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='yellow'
          >
            Inactive
          </Tag>
        );
      }
    },
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Status',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'LAST LOGIN',
    accessor: 'last_login',
  },
];

export default function UserListDataTable2(props) {
  const toast = useToast();
  const [tableData, changeTable] = useState(props.data);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    changeTable(props.data);
  }, [props]);

  const navigate = useNavigate();

  return (
    <div className='datatable-main datatable-controls'>
      {props.data ? (
        <DataTable data={tableData} columns={columns} showFilter chooseRows>
          {{
            rowFooter: (row: any) => (
              <Flex gap={1}>
                <Button variant='mojoPrimaryGhost' onClick={() => {}}>
                  <EditIcon onClick={() => props.addEditUser(row.UserId)} />
                </Button>
                <ButtonDanger
                  onClick={async () => await props.deleteUser(row.UserId)}
                >
                  <DeleteIcon />
                </ButtonDanger>                
              </Flex>
            ),
          }}
        </DataTable>
      ) : (
        <DataTableSkeleton columns={columns}>
          {{ rowFooter: () => <></> }}
        </DataTableSkeleton>
      )}
    </div>
  );
}
