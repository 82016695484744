import {
  Stat,
  StatLabel,
  StatNumber,
  CardBody,
  Card,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  CardHeader,
  Box,
  Flex, 
  Divider,
  HStack,
  Text
} from "@chakra-ui/react";
import './Home.scss';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';


function NumberStat({ label, number }: { label: string, number: number }) {
  return (
    <Card minWidth={'20ch'}>
      <CardBody>
        <Stat>
          <StatLabel>{label}</StatLabel>
          <StatNumber>{number}</StatNumber>
        </Stat>
      </CardBody>
    </Card>
  )
}

function LastActions({ label, columns, data }: { label: string, columns: string[], data: any[] }) {
  return (
    <Card>
      <CardHeader>{label}</CardHeader>
      <CardBody>
        <TableContainer>
          <Table size='sm'>
            <Thead>
              <Tr>
                {columns.map(column => <Th key={column}>{column}</Th>)}
              </Tr>
            </Thead>
            <Tbody>
              {data.map(item => (
                <Tr key={item.id}>
                  {columns.map(column => (
                    <Td>{item[column]}</Td>
                  ))}
                </Tr>  
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export function Home() {


  const { data: tenantData } = useMojoFetch("/api/v1/Clients/", "get");
  const clientCount = tenantData ? tenantData.length : 0;

  const { data: groupData } = useMojoFetch("/api/v1/Groups/", "get");
  const groupCount = groupData ? groupData.length : 0;

  const { data: userData } = useMojoFetch("/api/v1/Users/", "get");
  const userCount = userData ? userData.length : 0;

  const { data: partnerData } = useMojoFetch("/api/v1/Vendors/all/", "get");
  const partnerCount = partnerData ? partnerData.length : 0;

  const { data: contactData } = useMojoFetch("/api/v1/Contacts/all/", "get");
  const contactCount = contactData ? contactData.length : 0;
  console.log(contactData)

  const { data: integrationData } = useMojoFetch("/api/v1/Integrations/all/", "get");
  const integrationCount = integrationData ? integrationData.length : 0;
  console.log(integrationData)
  
  const data = [
    { id: '012', action: 'Delete user', user: 'AMG Mojo', client: 'Honda Austin',  performed: '1 hour ago' },
    { id: '345', action: 'Change permissions', user: 'AMG Mojo', client: 'Toyota Miami', performed: '1 day ago' },
  ];
  return (
    <Box className='home-page--wrapper'>
        <Flex className="home-page--header">
            <Flex className="home-header--left">
            <Heading className="home-heading">ADMIN HEALTH</Heading>
            </Flex>
        </Flex>
        <Flex className="home-page--body">
        <HStack className="home-card-stack">
          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Clients</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{clientCount}</Text>
                </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Contacts</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{contactCount}</Text>
                </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Groups</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{groupCount}</Text>
                </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Integrations</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{integrationCount}</Text>
                </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Users</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{userCount}</Text>
                </Flex>
            </CardBody>
          </Card>

          <Card className="home-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">Vendors</Flex>
            </CardHeader>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                  <Text>{partnerCount}</Text>
                </Flex>
            </CardBody>
          </Card>

        </HStack>
      
      
      
      
      
      
      
      {/* <Flex gap={'10'} flexFlow={'row wrap'} justifyContent={'space-between'}>
        <NumberStat label="# of clients" number={43} />
        <NumberStat label="# of contacts" number={32} />
        <NumberStat label="# of groups" number={32} />
        <NumberStat label="# of integrations" number={45} />
        <NumberStat label="# of users" number={75} />
        <NumberStat label="# of vendors" number={12} />
      </Flex>
      <Flex gap={'10'}>
        <LastActions label="Last actions" columns={['action', 'user', 'client', 'performed']} data={data} />
      </Flex> */}
    </Flex>
    </Box>
  )
}