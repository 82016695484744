import { useEffect, useState } from 'react';
import {
    Select,
    HStack,
    Box,
    Grid,
    GridItem,
    useColorModeValue,
    Heading,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    CloseButton,
    useDisclosure,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import WebsiteTrafficWidget from 'components/Widgets/WebsiteTrafficWidget';
import InventoryWidget from 'components/Widgets/InventoryWidget';
import SoldWidget from 'components/Widgets/SoldWidget';
import InvestmentChart from 'components/Charts/InvestmentChart';
import InventoryChart from 'components/Charts/InventoryChart';
import WebsiteTrafficChart from 'components/Charts/WebsiteTrafficChart';
import SoldChart from 'components/Charts/SoldChart';
//import ServiceRetentionChart from 'components/Charts/ServiceRetentionChart';
import './dashboard-layout.scss';
import DateFilter from 'components/DateFilter/DateFilter';
import InvestmentWidget from 'components/Widgets/InvestmentWidget';
import { useReportEffect } from 'api/useRepoEffect';
import { useMojoEffect } from 'api/useMojoEffect';
import { connect } from 'react-redux';

function Dashboard(props) {
    const {
        isOpen: isAlertVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: false });
    const [key, setKey] = useState(1);
    const [startDate, setStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
    );
    const [endDate, setEndDate] = useState(new Date());

    const { runWithId: getClients } = useMojoEffect(`/api/v1/clients?`, 'get');
    const [clients, setClients] = useState<any[]>([]);
    const [isFilterLoading, setFilterLoading] = useState(false);
    const [isClientListLoading, setClientListLoading] = useState(false);
    const { run: getInvestmentData } = useReportEffect('/investments', 'post');
    const { run: getInventoryData } = useReportEffect('/inventory', 'post');
    const { run: getTrafficData } = useReportEffect('/websitemetrics', 'post');
    const { run: getSalesData } = useReportEffect('/sales', 'post');

    const [investmentSeries, setInvestmentSeries] = useState<{
        categories: string[];
        dataSeries: any[];
        avg_cost_per_lead: Number | null;
    }>({ categories: [], dataSeries: [], avg_cost_per_lead: null });
    const [inventorySeries, setInventorySeries] = useState<{
        categories: string[];
        dataSeries: any[];
        avg_leads_per_invt: Number;
    }>({ categories: [], dataSeries: [], avg_leads_per_invt: 0 });
    const [trafficSeries, setTrafficSeries] = useState<{
        categories: string[];
        dataSeries: any[];
        avg_leads_per_visitor: Number;
    }>({ categories: [], dataSeries: [], avg_leads_per_visitor: 0 });
    const [salesSeries, setSalesSeries] = useState<{
        categories: string[];
        dataSeries: any[];
        avg_monthly_sold_rate: Number;
    }>({ categories: [], dataSeries: [], avg_monthly_sold_rate: 0 });
    const [clientId, setClientId] = useState(() => {
        return localStorage.getItem('clientId') || 'All';
    });
    const [department, setDepartment] = useState(() => {
        return localStorage.getItem('department') || '';
    });
    const [source, setSource] = useState(() => {
        return localStorage.getItem('source') || '';
    });
    const [activeWidgetState, setActiveWidget] = useState('InventoryWidget');

    useEffect(() => {
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('department', department);
        localStorage.setItem('source', source);
    }, [clientId, department, source]);

    useEffect(() => {
        const fetch = async () => {
            setClientListLoading(true);
            const [data, error] = await getClients(`groupId=${props.groupId}`);
            if (error === null) {
                const myTenant = data.find((x) => x.TenantId === clientId);
                if (myTenant === undefined) {
                    setInvestmentSeries({
                        categories: [],
                        dataSeries: [],
                        avg_cost_per_lead: null,
                    });
                    setInventorySeries({
                        categories: [],
                        dataSeries: [],
                        avg_leads_per_invt: 0,
                    });
                    setTrafficSeries({
                        categories: [],
                        dataSeries: [],
                        avg_leads_per_visitor: 0,
                    });
                    setSalesSeries({
                        categories: [],
                        dataSeries: [],
                        avg_monthly_sold_rate: 0,
                    });
                    setClientId('');
                    setKey(key + 1);
                }
                setClients(data);
            } else {
                //setError(error);
            }
            setClientListLoading(false);
        };
        fetch();
    }, [props.groupId]);

    const handleWidgetClick = (widgetName: string) => {
        setActiveWidget(widgetName);
    };

    async function handleClientChange(e) {
        setFilterLoading(true);
        if (e.target.value === '') {
            setInvestmentSeries({
                categories: [],
                dataSeries: [],
                avg_cost_per_lead: null,
            });
            setInventorySeries({
                categories: [],
                dataSeries: [],
                avg_leads_per_invt: 0,
            });
            setTrafficSeries({
                categories: [],
                dataSeries: [],
                avg_leads_per_visitor: 0,
            });
            setSalesSeries({
                categories: [],
                dataSeries: [],
                avg_monthly_sold_rate: 0,
            });
            setClientId('');
            setKey(key + 1);
            setFilterLoading(false);
            onClose();
            return;
        }
        const client = clients.find((c) => c.TenantId === e.target.value);
        if (client !== undefined && client.TenantId !== clientId) {
            setClientId(client.TenantId);
            await doEverything(client.TenantId, startDate, endDate);
        }
        setFilterLoading(false);
    }

    const handleDepartmentChange = (e) => {
        setDepartment(e.target.value);
    };

    const handleSourceChange = (e) => {
        setSource(e.target.value);
    };

    async function handleDateChange(range) {
        const [start_date, end_date] = range;
        if (startDate === null || end_date === null) {
            return;
        }
        setFilterLoading(true);
        setStartDate(start_date);
        setEndDate(end_date);
        await doEverything(clientId, start_date, end_date);
        setFilterLoading(false);
    }

    async function doEverything(client_id, start_date, end_date) {
        onClose();

        if (client_id === undefined || client_id === 'All') {
            return;
        }

        const postBody = {
            filters: {
                tenants: [client_id],
                // round up start date to start of the month of selected date
                startDate: moment(start_date)
                    .utc()
                    .startOf("month")
                    .startOf("day")
                    .valueOf(),
                // round down utc end date to end of the month of selected date
                endDate: moment(end_date)
                    .utc()
                    .endOf("month")
                    .endOf("day")
                    .valueOf(),
            },
        };

        setFilterLoading(true);
        const [investmentData, errors1] = await getInvestmentData(postBody);
        const [inventoryData, errors2] = await getInventoryData(postBody);
        const [trafficData, errors3] = await getTrafficData(postBody);
        const [salesData, errors4] = await getSalesData(postBody);
        setFilterLoading(false);

        if (
            inventoryData === null ||
            inventoryData.data === undefined ||
            inventoryData.data === null ||
            inventoryData.data.length === 0
        ) {
            onOpen();
        } else {
            onClose();
        }

        const years: Set<number> = new Set();
        const months: Map<number, number[]> = new Map();

        //Do all the months between startDate and endDate
        let d = new Date(start_date);
        while (d <= end_date) {
            const y = d.getFullYear();
            years.add(y);
            if (months[y] === undefined) {
                months[y] = [];
            }
            months[y].push(d.getMonth());
            d.setMonth(d.getMonth() + 1);
        }
        setInvestmentSeries({
            ...adjustData(investmentData.data, months, years),
            avg_cost_per_lead: investmentData.avg_cost_per_lead,
        });
        setInventorySeries({
            ...adjustData(inventoryData.data, months, years),
            avg_leads_per_invt: inventoryData.avg_leads_per_invt,
        });
        setTrafficSeries({
            ...adjustData(trafficData.data, months, years),
            avg_leads_per_visitor: trafficData.avg_leads_per_visitor,
        });
        setSalesSeries({
            ...adjustData(salesData.data, months, years),
            avg_monthly_sold_rate: salesData.avg_monthly_sold_rate,
        });
        setKey(key + 1);
    }

    function adjustData(data: any[], monthSeries: Map<number, number[]>, years) {
        const result = { categories: [] as string[], dataSeries: [] as any[] };
        const monthLabels = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'June',
            'July',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ];

        for (const year of years) {
            for (const month of monthSeries[year]) {
                result.categories.push(monthLabels[month]);
                if (data !== undefined && data !== null) {
                    const node = data.find((x) => x.year === year && x.month === month);
                    if (node !== undefined) {
                        result.dataSeries.push(node);
                    } else {
                        result.dataSeries.push({ year: year, month: month });
                    }
                }
            }
        }
        return result;
    }

    const bg = useColorModeValue('white.100', '#121212');
    const filterBg = useColorModeValue('white', '#282828');
    const fontColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', '#3f3f3f');

    return (
        <>
            {isAlertVisible && (
                <Alert status='error' mb='2em'>
                    <Flex>
                        <AlertIcon />
                        <AlertTitle>Inventory data unavailable!</AlertTitle>
                        <AlertDescription>
                            Please use different dates or try again in a couple of days.
                        </AlertDescription>
                    </Flex>
                    <Spacer />
                    <CloseButton onClick={onClose} />
                </Alert>
            )}
            <Box className='dashboard-container' bg={bg}>
                <Box className='dashboard-header'>
                    <Heading className='dashboard-heading' color={fontColor}>
                        HOME DASHBOARD
                    </Heading>
                    <HStack className='dashboard-filters' color={fontColor}>
                        <Flex className='spinner-container'>
                            {isClientListLoading && <Spinner />}
                            {!isClientListLoading && (
                                <Select
                                    value={clientId}
                                    className='dashboard-filter dashboard-filter-company'
                                    h='2.5rem'
                                    w='18.75rem'
                                    bg={filterBg}
                                    placeholder='Company Filter'
                                    border={borderColor}
                                    _placeholder={{ color: fontColor, opacity: 1 }}
                                    onChange={(e) => handleClientChange(e)}
                                >
                                    {clients.map((client) => (
                                        <option key={client.TenantId} value={client.TenantId}>
                                            {client.name}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </Flex>
                        <Select
                            className='dashboard-filter dashboard-filter-department'
                            h='2.5rem'
                            w='18.75rem'
                            bg={filterBg}
                            placeholder='Department Filter'
                            onChange={handleDepartmentChange}
                        />
                        <Select
                            className='dashboard-filter dashboard-filter-source'
                            h='2.5rem'
                            w='18.75rem'
                            bg={filterBg}
                            placeholder='Source Filter'
                            onChange={handleSourceChange}
                        />
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                        />
                    </HStack>
                </Box>
                <Flex className='dashboard-layout'>
                    <Flex className='widget-column'>
                        <InventoryWidget
                            key={key}
                            data={inventorySeries}
                            isActive={activeWidgetState === 'InventoryWidget'}
                            onClick={() => handleWidgetClick('InventoryWidget')}
                            isLoading={isFilterLoading}
                        />
                        <InvestmentWidget
                            key={key + 1}
                            data={investmentSeries}
                            isActive={activeWidgetState === 'InvestmentWidget'}
                            onClick={() => handleWidgetClick('InvestmentWidget')}
                            isLoading={isFilterLoading}
                        />
                        <WebsiteTrafficWidget
                            key={key + 2}
                            data={trafficSeries}
                            isActive={activeWidgetState === 'WebsiteTrafficWidget'}
                            onClick={() => handleWidgetClick('WebsiteTrafficWidget')}
                            isLoading={isFilterLoading}
                        />
                        <SoldWidget
                            key={key + 3}
                            data={salesSeries}
                            isActive={activeWidgetState === 'SoldWidget'}
                            onClick={() => handleWidgetClick('SoldWidget')}
                            isLoading={isFilterLoading}
                        />
                    </Flex>
                    <Flex className='chart-column'>
                        {activeWidgetState === 'InventoryWidget' && (
                            <InventoryChart
                                data={inventorySeries}
                                key={key + 4}
                                isLoading={isFilterLoading}
                            />
                        )}
                        {activeWidgetState === 'InvestmentWidget' && (
                            <InvestmentChart
                                data={investmentSeries}
                                key={key + 5}
                                isLoading={isFilterLoading}
                            />
                        )}
                        {activeWidgetState === 'WebsiteTrafficWidget' && (
                            <WebsiteTrafficChart
                                data={trafficSeries}
                                key={key + 6}
                                isLoading={isFilterLoading}
                            />
                        )}
                        {activeWidgetState === 'SoldWidget' && (
                            <SoldChart
                                data={salesSeries}
                                key={key + 7}
                                isLoading={isFilterLoading}
                            />
                        )}
                        {/*{activeWidgetState === 'ServiceRetentionWidget' && (
                            <ServiceRetentionChart
                                key={key + 8}
                                isLoading={isFilterLoading}
                            />
                        )} */}
                    </Flex>
                </Flex>
            </Box>
        </>
    );
}

const select = (state) => {
    return { groupId: state.app.selectedGroupId };
};
export default connect(select)(Dashboard);
