import {
  FormLabel,
  FormControl,
  Grid,
  Switch,
  Select,
  Flex,
  Button,
  useColorModeValue,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';

import { FloatingLabelInput } from '../../../components/FloatingLabelInput';
import { useEffect, useState } from 'react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';

export default function UserForm(props) {
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [strengthMessage, setStrengthMessage] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const toggleShowPass1 = () => setShowPass1(!showPass1);
  const toggleShowPass2 = () => setShowPass2(!showPass2);
  const [myRoleOptions, setOptions] = useState<{ id: string; name: string }[]>(
    []
  );

  useEffect(() => {
    setOptions(props.roleOptions);
  }, [props.roleOptions]);

  const myReset = () => {
    setPass1('');
    setPass2('');
    props.resetForm();
  };

  const checkPassword = (password: string) => {
    let message = '';
    let invalid = false;

    if (pass1.length < 10) {
      message = 'Password must be at least 10 characters.';
      invalid = true;
    } else if (!/[A-Z]/.test(pass1)) {
      message = 'Password must include at least one uppercase letter.';
      invalid = true;
    } else if (!/[a-z]/.test(pass1)) {
      message = 'Password must include at least one lowercase letter.';
      invalid = true;
    } else if (!/[0-9]/.test(pass1)) {
      message = 'Password must include at least one number.';
      invalid = true;
    } else if (!/[!,@,#,$,%,^,&,*]/.test(pass1)) {
      message = 'Password must include at least one special character.';
      invalid = true;
    } else {
      message = 'Strong password!';
      invalid = false;
    }

    setStrengthMessage(message);
    setIsInvalid(invalid);
  };

  const handleRoleChange = (e) => {
    const role = props.roles.find((r) => r.Id === e.target.value).name;
    let list = [];
    if (role.includes('Organization')) {
      list = props.entities.organizations;
      props.setIsClient(false);
    } else if (role.includes('Agency')) {
      list = props.entities.agencies;
      props.setIsClient(false);
    } else if (role.includes('Group')) {
      list = props.entities.groups;
      props.setIsClient(false);
    } else if (role.includes('Client')) {
      props.setIsClient(true);
    }

    setOptions(list);
    props.setRoleOptions(list);
  };

  const handleChange1 = (event) => {
    setPass1(event.target.value);
    checkPassword(pass1);
  };

  const handleChange2 = (event) => {
    setPass2(event.target.value);
    checkPassword(pass2);
  };

  const bg = useColorModeValue('white.100', 'black.100');
  const color = useColorModeValue('black.700', 'white.100');
  const navigate = useNavigate();

  return (
    <Grid
      templateColumns='repeat(2, 1fr)'
      columnGap='12px'
      rowGap='15px'
      marginTop='4rem'
      marginLeft='2rem'
    >
      <FormControl key={'details'}>
        <FormLabel w='10rem'>USER DETAILS</FormLabel>
      </FormControl>
      <FormControl display='flex' key={'active'}>
        <FormLabel>Active?</FormLabel>
        <Switch
          id='active'
          name='activeFlag'
          size='md'
          {...props.register('activeFlag')}
        />
        <FormLabel ml='2rem'>Blocked?</FormLabel>
        <Switch
          id='block'
          name='blockFlag'
          size='md'
          {...props.register('blockFlag')}
        />
      </FormControl>
      <FloatingLabelInput
        placeholder='First Name'
        isRequired={true}
        {...props.register('firstName')}
        type='text'
        key={'fname'}
      />

      <FloatingLabelInput
        placeholder='Last Name'
        isRequired={true}
        {...props.register('lastName')}
        type='text'
        key={'lname'}
      />

      <FloatingLabelInput
        placeholder='Email'
        isRequired={true}
        {...props.register('email')}
        type='text'
        key={'email'}
      />

      <FloatingLabelInput
        placeholder='Phone'
        isRequired={true}
        {...props.register('phone')}
        type='text'
        key={'phone'}
      />

      <FormControl isRequired variant='floating' key={'roleselect'}>
        <Select
          defaultValue={props.defaultRoleValue}
          placeholder='Select...'
          height='45px'
          borderRadius='5'
          bg={bg}
          color={color}
          border='gray.200'
          {...props.register('userRole')}
          onChange={handleRoleChange}
        >
          {props.roles.map((r) => (
            <option key={r.Id} value={r.Id}>
              {r.name}
            </option>
          ))}
        </Select>
        <FormLabel style={{ paddingRight: '8px' }}>Role</FormLabel>
      </FormControl>

      {myRoleOptions.length > 0 && (
        <FormControl variant='floating' isRequired key={'optionselect'}>
          <Select
            placeholder='Select...'
            height='45px'
            borderRadius='5'
            bg={bg}
            color={color}
            border='gray.200'
            {...props.register('userRoleOption')}
          >
            {myRoleOptions.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </Select>
          <FormLabel style={{ paddingRight: '8px' }}>Entities</FormLabel>
        </FormControl>
      )}

      {myRoleOptions.length === 0 && <FormControl></FormControl>}
      <FormControl
        isInvalid={isInvalid}
        onChange={handleChange1}
        key={'password'}
      >
        <InputGroup>
          <FloatingLabelInput
            placeholder='Password'
            isRequired={true}
            {...props.register('password')}
            type={showPass1 ? 'text' : 'password'}
            value={pass1}
            isDisabled={props.userId !== undefined && props.userId !== ''}
          />
          <InputRightElement>
            <Button
              variant='link'
              onClick={toggleShowPass1}
              aria-label={showPass1 ? 'Hide password' : 'Show password'}
            >
              {showPass1 ? <ViewOffIcon /> : <ViewIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage color='red'>{strengthMessage}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={pass1 !== pass2 || isInvalid}
        onChange={handleChange2}
        key={'password2'}
      >
        <InputGroup>
          <FloatingLabelInput
            placeholder='Re-type Password'
            isRequired={true}
            {...props.register('passwordCopy')}
            type={showPass2 ? 'text' : 'password'}
            value={pass2}
            isDisabled={props.userId !== undefined && props.userId !== ''}
          />
          <InputRightElement>
            <Button
              variant='link'
              onClick={toggleShowPass2}
              aria-label={showPass2 ? 'Hide password' : 'Show password'}
            >
              {showPass2 ? <ViewOffIcon /> : <ViewIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage color='red'>
          {pass1 !== pass2 ? 'Passwords must match.' : strengthMessage}
        </FormErrorMessage>
      </FormControl>
      <FormControl key={'button1'}>
        <Button
          leftIcon={<AddIcon />}
          variant='mojoPrimary'
          aria-label='submit'
          size='sm'
          display={
            props.userId === undefined || props.userId === '' || props.isLoading
              ? 'none'
              : 'flex'
          }
          onClick={() => {
            myReset();
            navigate('/users/new');
          }}
        >
          ADD NEW USER
        </Button>
      </FormControl>
      <FormControl key={'button2'}>
        <Flex justify='right'>
          <Button
            aria-label='submit'
            leftIcon={
              props.userId === undefined || props.userId === '' ? (
                <AddIcon />
              ) : (
                <EditIcon />
              )
            }
            type='submit'
            isDisabled={false}
            variant='mojoPrimary'
            isLoading={props.isLoading}
            size='sm'
            loadingText='Submitting'
          >
            {props.userId === undefined || props.userId === ''
              ? 'ADD NEW USER'
              : 'UPDATE USER'}
          </Button>
        </Flex>
      </FormControl>
    </Grid>
  );
}
