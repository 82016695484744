import {
  Button,
  FormControl,
  Skeleton as ChakraSkeleton,
  Editable,
  EditablePreview,
  Select,
  Input,
  FormLabel,
  EditableTextarea,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import { useMojoFetch } from 'api/useMojoFetch';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FloatingLabelInput } from 'components/FloatingLabelInput';
import './VendorForm.scss';

const labelProps = {
  transform: 'scale(0.7) translateY(30px)',
};

const Skeleton: FC<{ children: ReactNode; isLoaded: boolean }> = ({
  children,
  ...props
}) => {
  return (
    <ChakraSkeleton {...props} startColor='gray.100' endColor='gray.200'>
      {children}
    </ChakraSkeleton>
  );
};

export function VendorForm({
  submit,
  defaultValues,
  btnTitle,
  btnIcon,
  isCreatingNew,
  isLoading,
  isSubmitting,
  revieweesData, vendorTypesData, vendorRecommendsData
}) {
  const bg = useColorModeValue('white.100', 'black.100');
  const color = useColorModeValue('black.200', 'white.100');


  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({ values: defaultValues });

  const [revId, setRevId] = useState(null as any);
  const [myNotes, setNotes] = useState('');

  useEffect(() => {
    setRevId((defaultValues.RevieweeId ??= null));
    setNotes((defaultValues.notes ??= ''));
  }, [defaultValues]);

  const vendorTypesOptions = vendorTypesData ? (
    vendorTypesData.map(({ VendorTypeId, name }) => (
      <option value={VendorTypeId} key={VendorTypeId}>
        {name}
      </option>
    ))
  ) : (
    <option></option>
  );
  const revieweeOptions = revieweesData ? (
    revieweesData.map(({ firstName, lastName, UserId }) => (
      <option value={UserId} key={UserId}>
        {firstName + ' ' + lastName}
      </option>
    ))
  ) : (
    <option></option>
  );
  const vendorRecommendsOptions = vendorRecommendsData ? (
    vendorRecommendsData.map(({ VendorRecommendId, description }) => (
      <option value={VendorRecommendId} key={VendorRecommendId}>
        {description}
      </option>
    ))
  ) : (
    <option></option>
  );

  const isInvalid = !!errors.name || !!errors.VendorId || !!errors.VendorTypeId;

  const buttonEnabled =
    !isInvalid && !isLoading && !isSubmitting;
  const buttonIsLoading = isCreatingNew
    ? isLoading || isSubmitting
      ? true
      : false
    : isLoading || isSubmitting 
    ? true
    : false;
  const buttonLoadingText =
    isLoading  ? 'LOADING' : undefined;

  if (defaultValues?.reviewDate) {
    defaultValues.reviewDate = new Date(defaultValues?.reviewDate)
      .toISOString()
      .split('T')[0];
  }
  if (defaultValues?.companyLaunch) {
    defaultValues.companyLaunch = new Date(defaultValues?.companyLaunch)
      .toISOString()
      .split('T')[0];
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className='client-form'>
        <div className='client-form-subheader'>
          <h2>PARTNER DETAILS</h2>
        </div>
        <div className='client-form-body budget-form-body'>
          <FormControl variant='floating' isRequired>
            <Input type='text' {...register('name')} />
            <FormLabel style={{ paddingRight: '8px' }} bg={bg} color={color}>
              Name
            </FormLabel>
          </FormControl>
          <Skeleton isLoaded={!!vendorTypesData}>
            <FormControl variant='floating' isRequired>
              <Select
                placeholder='Select...'
                isInvalid={!!errors.VendorTypeId}
                {...register('VendorTypeId')}
              >
                {vendorTypesOptions}
              </Select>
              <FormLabel style={{ paddingRight: '8px' }} bg={bg} color={color}>
                Partner type
              </FormLabel>
            </FormControl>
          </Skeleton>
          <Skeleton isLoaded={!!revieweesData}>
            <FormControl variant='floating'>
              {buttonEnabled ? (
                <>
                  <Select
                    value={revId}
                    placeholder='Select...'
                    isInvalid={!!errors.RevieweeId}
                    {...register('RevieweeId')}
                    onChange={(e) => {
                      setRevId(e.target.value);
                    }}
                  >
                    {revieweeOptions}
                  </Select>
                  <FormLabel
                    style={{ paddingRight: '8px' }}
                    bg={bg}
                    color={color}
                  >
                    Reviewee
                  </FormLabel>
                </>
              ) : (
                <Spinner />
              )}
            </FormControl>
          </Skeleton>
          <Skeleton isLoaded={!!vendorRecommendsData}>
            <FormControl variant='floating'>
              <Select
                placeholder='Select...'
                {...register('VendorRecommendId')}
              >
                {vendorRecommendsOptions}
              </Select>
              <FormLabel style={{ paddingRight: '8px' }} bg={bg} color={color}>
                AMG Recommend
              </FormLabel>
            </FormControl>
          </Skeleton>
          <FloatingLabelInput
            defaultValue={defaultValues?.presenter}
            type='text'
            placeholder='Presenter'
            {...register('presenter', {})}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.reviewDate}
            placeholder='Review Date'
            type='date'
            {...register('reviewDate', {
              valueAsDate: true,
            })}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.companyLaunch}
            placeholder='Company Launch'
            type='date'
            {...register('companyLaunch', {
              valueAsDate: true,
            })}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.website}
            placeholder='Website URL'
            {...register('website', {})}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.numberOfClients}
            placeholder='# of Clients'
            type='number'
            {...register('numberOfClients', {
              valueAsNumber: true,
            })}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.numberOfEmployees}
            placeholder='# of Employees'
            type='number'
            {...register('numberOfEmployees', {
              valueAsNumber: true,
            })}
          />
          <FloatingLabelInput
            defaultValue={defaultValues?.acctsPerRep}
            placeholder='Accts per Rep.'
            type='number'
            {...register('acctsPerRep', {
              valueAsNumber: true,
            })}
          />
        </div>
        <div className='client-form-footer'>
          <FormLabel w={'4rem'} {...labelProps} bg={bg} color={color}>
            Notes
          </FormLabel>
          <Editable
            value={myNotes}
            onChange={(e) => {
              setNotes(e);
            }}
          >
            <EditablePreview />
            <EditableTextarea {...register('notes')} id='notes' />
          </Editable>
          <div>
            <Button
              id='subm_btn'
              isLoading={buttonIsLoading}
              isDisabled={!buttonEnabled}
              leftIcon={btnIcon}
              loadingText={buttonLoadingText}
              variant='mojoPrimary'
              type='submit'
            >
              {btnTitle}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
