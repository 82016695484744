
import { Box, Center, Flex, Image, Text, Heading, HStack, Card, CardHeader, CardBody, Icon, Link, useColorModeValue, Spinner } from '@chakra-ui/react';
import './AdminPage.scss';
import { NavLink } from "react-router-dom";
import { SlOrganization } from "react-icons/sl";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbClock } from "react-icons/tb";
import { Divider } from 'antd';
import { LiaHandshake } from "react-icons/lia";
import { BsPeople } from "react-icons/bs";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { useMojoFetch } from 'api/useMojoFetch';



function AdminPage() {

    // ask about card color, I think the theme color is too dark so keeping shade lighter (abby)
    // const cardBg = useColorModeValue('white.100', '#282828');

    

    const { data: organizationData, isLoading: organizationLoading } = useMojoFetch("/api/v1/Organizations/", "get");
    const organizationCount = organizationData ? organizationData.length : 0;

    const { data: groupData, isLoading: groupLoading } = useMojoFetch("/api/v1/Groups/", "get");
    const groupCount = groupData ? groupData.length : 0;

    const { data: userData, isLoading: userLoading } = useMojoFetch("/api/v1/Users/", "get");
    const userCount = userData ? userData.length : 0;

    const { data: partnerData, isLoading: partnerLoading } = useMojoFetch("/api/v1/Vendors/all/", "get");
    const partnerCount = partnerData ? partnerData.length : 0;

    const { data: agencyData, isLoading: agencyLoading } = useMojoFetch("/api/v1/Agencies/", "get");
    const agencyCount = agencyData ? agencyData.length : 0;

    console.log("this is the loading state", organizationLoading)
    console.log("test test test")

    const countSpinner = (isLoading, count) => {
        if (isLoading) {
            return <Spinner size="sm" />;
        }
        return count;
    };
    

    return (
        <Box className='admin-page--wrapper'>
            <Flex className="admin-page--header">
                <Flex className="admin-header--left">
                <Heading className="admin-heading">ADMIN</Heading>
                </Flex>
            </Flex>
            <Flex className="admin-page--body">
                <Flex
                    className="admin-nav--links"
                    direction="column"
                    align="center"
                    justify="flex-start"
                    p={2}
                    mb="15px"
                    as="nav"
                >
                    <NavLink to="/agencies" className="admin-card--link" aria-label="Agencies">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Agencies</Flex>
                            </CardHeader>
                            <Flex className="admin-card-count">{countSpinner(agencyLoading, agencyCount)}</Flex>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={TbClock} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/groups" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Groups</Flex>
                            </CardHeader>
                            <Flex className="admin-card-count">{countSpinner(groupLoading, groupCount)}</Flex>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={HiOutlineUserGroup} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/organizations" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Organizations</Flex>
                            </CardHeader>
                            <Flex className="admin-card-count">{countSpinner(organizationLoading, organizationCount)}</Flex>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={SlOrganization} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/partners" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Partners</Flex>
                            </CardHeader>
                            <Flex className="admin-card-count">{countSpinner(partnerLoading, partnerCount)}</Flex>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={LiaHandshake} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/users" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Users</Flex>
                            </CardHeader>
                            <Flex className="admin-card-count">{countSpinner(userLoading, userCount)}</Flex>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={BsPeople} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/home" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Admin Health</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={MdOutlineHealthAndSafety} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>
                </Flex>
            </Flex>
        </Box>
    );
}

export default AdminPage;